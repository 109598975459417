<template>
    <div class="page-header">
        <el-page-header title="返回" content="新建笔记" @back="goBack" />
        <div class="page-header-button">
            <el-button type="success" size="default" @click="submitForm({name, content})">
                保存
            </el-button>
        </div>
    </div>
    <div id="editor">
        <div id="preview">
            <content-show :content="preContent"/>
        </div>
        <div id="input">
            <note-form @name-change="onNameChange"
                       @content-change="onContentChange"
                       @submit-note="submitForm"
                       :id="id"
                       :name="name"
                       :content="content"/>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";
    import ContentShow from '@/components/notes/ContentShow.vue';
    import NoteForm from "@/components/notes/NoteForm";
    import {NOTES} from "@/constants/api";

    export default defineComponent({
        name: "New",
        components: {
            ContentShow,
            NoteForm
        },
        setup() {
            const router = useRouter();
            const goBack = () => {
                router.back();
            }

            return {
                goBack,
            }
        },
        data() {
            return {
                id: null,
                name: '',
                content: '',
                preContent: ''
            }
        },
        created() {
            this.create({name: null, content: null});
        },
        methods: {
            onNameChange(name) {
                this.name = name;
            },
            onContentChange(content) {
                this.content = content;
                setTimeout(() => {
                    this.preContent = this.content;
                }, 500)
            },
            submitForm({name, content}) {
                if (this.id != null) {
                    this.update({name, content});
                } else {
                    this.create({name, content});
                }
            },
            create({name, content}) {
                const vm = this;
                this.axios.post(NOTES, {
                    name: name,
                    content: content,
                    parentId: this.$route.params.parentId,
                    teamId: vm.$store.state.currentTeam?.id
                }).then(function (response) {
                    vm.id = response.data.note.id;
                    vm.name = response.data.note.name;
                    // ElMessage({
                    //     showClose: true,
                    //     message: '保存成功',
                    //     type: 'success',
                    // });
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '新建笔记失败',
                            type: 'error',
                        });
                    }
                });
            },
            update({name, content}) {
                this.axios.patch(NOTES, {
                    name: name,
                    content: content,
                    id: this.id,
                    teamId: this.$store.state.currentTeam?.id
                }).then(function (response) {
                    ElMessage({
                        showClose: true,
                        message: '保存成功',
                        type: 'success',
                    });
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '保存失败',
                            type: 'error',
                        });
                    }
                });
            }
        }

    })
</script>

<style scoped lang="less">
    html,
    body,
    #editor {
        margin: 0;
        height: 100%;
        font-family: "Helvetica Neue", Arial, sans-serif;
        color: #333;
    }

    .page-header {
        display: flex;
        margin-bottom: 16px;
        border-bottom: solid 1px #ebebeb;
        justify-content: space-between;
    }

    .page-header-button {
        line-height: 3.3;
        padding-right: 8px;
    }

    .el-page-header {
        padding: 16px 8px;
    }

    #input,
    #editor #preview {
        display: inline-block;
        width: 49%;
        height: 100%;
        vertical-align: top;
        box-sizing: border-box;
        padding: 0 20px;
        /*text-align: left;*/
    }

</style>